<template>
  <div v-loading="navLoading" class="header" style="justify-content: normal">
    <div class="index-logo">
      <div class="index-logo-image">
        <img
          :src="info.appLogoFileId ? '/api/file/view?code=' + info.appLogoFileId : info.defaultImg"
          style="width: 40px; height: 40px"
        />
      </div>
      <div class="index-logo-name">
        <span style="font-size: 20px">{{ info.appName }}</span>
      </div>
    </div>
    <div class="index-info">
      <span
        v-if="loginBtn"
        :class="['login-btn', { active: loginWay === 'notary' }]"
        @click="changeLoginType('notary', 'legalService')"
      >
        <i class="icon-house" />
        公证处登录
      </span>
      <span
        v-if="loginBtn"
        :class="['login-btn', { active: loginWay === 'people' }]"
        @click="changeLoginType('people', 'notaryCloud')"
      >
        <i class="icon-person" />
        当事人登录
      </span>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
// import defaultImg from '../assets/images/logo.png'
export default {
  name: 'Navigation',
  props: ['info', 'navLoading'],
  data() {
    return {
      // defaultImg: defaultImg
    }
  },

  computed: {
    ...mapState('login', ['loginBtn', 'loginWay'])
  },

  methods: {
    changeLoginType(type, platform) {
      this.setLoginWay(type)
      this.setPlatform(platform)
    },
    ...mapMutations({
      setLoginWay: 'login/setLoginWay',
      setPlatform: 'login/setPlatform'
    })
  }
}
</script>

<style lang="scss" scoped>
.header {
  height: 60px;
  width: 100%;
  line-height: 60px;
  padding: 0 80px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
  background: #fff;
}

.index-logo {
  display: inline-flex;
  height: 100%;
  width: 280px;
  margin-right: 30px;
  align-items: center;
  cursor: pointer;
}

.index-logo-image {
  display: flex;
}

.index-logo-name {
  padding: 0 0 0 20px;
  line-height: 60px;
}

.index-info {
  float: right;
}

.login-btn {
  position: relative;
  padding: 0 15px;
  font-size: 12px;
  color: #8499ad;
  cursor: pointer;

  &:not(:first-child)::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 20px;
    width: 1px;
    background: #dce5ec;
  }

  &:hover,
  &.active {
    color: #1890ff;

    i[class^='iconfont'] {
      color: #1890ff;
    }
  }

  i[class^='iconfont'] {
    font-size: 14px;
    color: #d0dce8;
  }
}

.clr {
  color: #8499ad;
}
.clr:hover,
.clr:focus {
  color: #409eff;
}
</style>
