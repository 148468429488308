import JSEncrypt from 'jsencrypt/bin/jsencrypt.min.js'

const PUBLIC_KEY =
  'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDOq+ukY+l/ewgbiHL3LawuPByKjaxQKTduaLmrtFb7NLm7mE/grS2tkUYvol6CnLGKb8Xr/KZIuWGxsV41LJEZPhYWB52NvdIALSwlkTlo5sezxVCKpEFgy8CZuHh5WxkYal/YhLUmbz2ZVKvNrQzBuaUxQ98Vb7dxq/EHANOmhQIDAQAB'

const jsencrypt = new JSEncrypt()
jsencrypt.setPublicKey(PUBLIC_KEY)

export function encrypt(text) {
  return jsencrypt.encrypt(text)
}
