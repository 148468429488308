<template>
  <el-container v-loading="navLoading" class="login-container">
    <el-header>
      <Navigation :info="applicationInfo" :navLoading="navLoading" />
    </el-header>
    <el-main class="bg-full">
      <div class="login-main">
        <!-- 图片区域 -->
        <div class="login-left">
          <k-swipper :bgImg="imageUrl" />
        </div>
        <!-- 登录块 -->
        <div class="login-wrap">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane v-if="loginTypes.includes('vcode')" name="vcode">
              <span slot="label">
                <!-- <i class="el-icon-date" /> -->
                {{ tabArr[0] }}
              </span>
            </el-tab-pane>
            <el-tab-pane v-if="loginTypes.includes('pwd')" name="pwd">
              <span slot="label">
                <!-- <i class="el-icon-date" /> -->
                {{ loginTypes.length === 1 ? '登录' : tabArr[1] }}
              </span>
            </el-tab-pane>
          </el-tabs>
          <el-form
            v-show="curActiveTab === 'vcode'"
            ref="codeForm"
            :model="form"
            :rules="rules"
            class="vcode-form"
            label-position="left"
            label-width="0px"
          >
            <el-form-item prop="phone">
              <el-input
                v-model="form.phone"
                class="login-username"
                type="text"
                auto-complete="off"
                placeholder="请输入手机号"
                @keyup.enter.native="handleSmsLogin"
              />
            </el-form-item>
            <el-form-item prop="code">
              <el-input
                v-model="form.code"
                auto-complete="off"
                placeholder="请输入验证码"
                class="login-code-input"
                @keyup.enter.native="handleSmsLogin"
              />
              <div class="login-code">
                <el-button
                  :disabled="phoneCodeCountDownValue > 0"
                  :loading="vcodeLoading"
                  type="primary"
                  class="code-btn"
                  @click.native.prevent="getPhoneCode"
                >
                  <span>
                    {{ phoneCodeCountDownValue === 0 ? '获取验证码' : phoneCodeCountDownValue + '秒可重发' }}
                  </span>
                </el-button>
              </div>
            </el-form-item>
            <el-form-item style="width: 100%">
              <el-button
                :loading="loading"
                type="primary"
                style="width: 100%"
                class="login-btn"
                @click.native.prevent="handleSmsLogin"
              >
                <span v-if="!loading">登 录</span>
                <span v-else>登 录 中...</span>
              </el-button>
            </el-form-item>
          </el-form>
          <el-form
            v-show="curActiveTab === 'pwd'"
            ref="userForm"
            :model="form"
            :rules="rules"
            label-position="left"
            label-width="0px"
          >
            <el-form-item prop="username">
              <el-input
                v-model="form.username"
                class="login-username"
                type="text"
                auto-complete="off"
                placeholder="请输入用户名"
                @keyup.enter.native="handleLogin"
              />
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                v-model="form.password"
                class="login-password"
                :type="showPwd ? 'text' : 'password'"
                auto-complete="off"
                placeholder="请输入密码"
                @keyup.enter.native="handleLogin"
              >
                <i
                  slot="suffix"
                  :class="[!showPwd ? 'icon-eye-open eye' : 'icon-eye-close eye']"
                  @click="showPwd = !showPwd"
                />
              </el-input>
            </el-form-item>
            <el-form-item v-if="vcodePresent && submitCount > 2" prop="code">
              <el-input
                v-model="form.code"
                auto-complete="off"
                placeholder="请输入图形验证码"
                class="login-code-input"
                @keyup.enter.native="handleLogin"
              />
              <div class="login-code">
                <img :src="codeUrl" @click="getImageCode" />
              </div>
            </el-form-item>
            <el-checkbox v-model="form.rememberMe" class="remember-me">记住密码</el-checkbox>
            <router-link
              v-if="loginWay === 'notary'"
              :to="{ path: '/findpassword', query: { clientFlag: clientFlag, applicationId: applicationId } }"
              class="find-ps__a"
            >
              <span class="find-ps">
                <i class="el-icon-warning" />
                忘记密码?
              </span>
            </router-link>
            <router-link v-else to="/findPs" class="find-ps__a">
              <span class="find-ps">
                <i class="el-icon-warning" />
                忘记密码?
              </span>
            </router-link>
            <el-form-item style="width: 100%">
              <el-button :loading="loading" type="primary" style="width: 100%" @click.native.prevent="handleLogin">
                <span v-if="!loading">登 录</span>
                <span v-else>登 录 中...</span>
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import { encrypt } from '@/utils/rsa'
import Cookies from 'js-cookie'
import Config from '@/config'
import Navigation from '@/components/Navigation'
import { mapState, mapMutations } from 'vuex'
import { getCodeImg, applicationGetInfo, preOpen, findSysBannerPage } from '@/api/login'
import { normalSendCode } from '@/api/common'
import defaultImg from '../assets/images/logo.png'

export default {
  components: { Navigation },
  data() {
    return {
      accountType: 2,
      navLoading: false,
      loginSource: 'UNIFIED_USER',
      applicationId: this.$route.query.applicationId,
      clientFlag: null,
      loginTypes: ['pwd'], //vcode: 验证码登录， pwd: 密码登录
      activeName: 'vcode',
      phoneCodeDisabled: false,
      phoneCodeCountDownValue: 0,
      timer: null,
      loading: false,
      redirect: undefined,
      vcodeLoading: false,
      showPwd: false,
      form: {
        username: null,
        password: null,
        code: null,
        uuid: null,
        rememberMe: false
      },
      phoneUuid: null,
      rules: {
        username: { required: true, trigger: 'blur', message: '请填写用户名' },
        password: { required: true, trigger: 'blur', message: '请填写用户密码' },
        code: { required: true, trigger: 'blur', message: '请填写验证码' },
        phone: { required: true, trigger: 'blur', message: '请输入手机号' }
      },
      vcodePresent: true,
      imageUrl: [require('@/assets/images/login-bg1.png')],
      codeUrl: null,
      allowedUserTypes: ['1', '3', '4'],
      submitCount: 0,
      applicationInfo: {},
      openInfo: {},
      defaultImg: defaultImg
    }
  },
  computed: {
    tabArr() {
      return this.loginWay === 'notary' ? ['验证码登录', '账号密码登录'] : ['个人用户', '机构用户']
    },
    curActiveTab() {
      return this.loginWay === 'notary' ? this.activeName : 'pwd'
    },
    ...mapState('login', ['loginWay', 'platform'])
  },
  created() {
    this.handlePlatform()
    this.getImageCode()
    if (this.$route.path !== '/login/notaryCloud') {
      this.getApplicationInfo()
    } else {
      this.applicationInfo = {
        appName: '鹭江公证处',
        defaultImg: this.defaultImg
      }
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  mounted() {
    this.getBanner()
    // c测试接口用,后续删除
    //getInfo({ username: 'csgzy', password: 'FaXin@123456' }).then(() => {})
    if (this.platform == 'legalService') {
      this.clientFlag = 'LEGAL_SERVICE'
    } else {
      this.clientFlag = 'PLATFORM_OPERATION'
    }
  },

  methods: {
    getApplicationInfo() {
      this.navLoading = true
      applicationGetInfo({
        applicationId: this.applicationId
      }).then((res) => {
        if (res) {
          this.applicationInfo = res.data
          this.addHtmlMeta()
        }
      }).finally(() => {
        this.navLoading = false
      })
    },
    addHtmlMeta() {
      if (this.applicationInfo.appName) {
        document.title = this.applicationInfo.appName
        if (this.applicationInfo.appName === '领事认证直通车') {
          let keywords = '领事、认证、公证、领事认证、出国办理、出国认证、认证代办'
          let description =
            '领事认证直通车是由法信公证云、公证处和外交部联合推出一款办理领事认证产品，一站式认证申请、方便快捷；公证处代办安全可靠，全程网办，随时随地在线查询，出国办事一步到位。'
          let title = '领事认证直通车—一站式领事认证申请、办理平台'
          document.title = title
          document.querySelector("meta[name='keywords']").setAttribute('content', keywords)
          document.querySelector("meta[name='description']").setAttribute('content', description)
        }

        if (this.applicationInfo.appLogoFileId) {
          let link = document.querySelector('link[rel*="icon') || document.createElement('link')
          link.type = 'image/x-icon'
          link.rel = 'shortcut icon'
          link.href = '/api/file/view?code=' + this.applicationInfo.appLogoFileId
          document.getElementsByTagName('head')[0].appendChild(link)
        }
      }
    },
    handleClick(tab) {
      if (tab.$slots.label[0].children[0].text === ' 机构用户 ') {
        this.accountType = '4'
      } else {
        this.accountType = '2'
      }
    },
    getBanner() {
      const params = {
        size: -1,
        page: 1,
        applicationId: this.$route.query.applicationId
      }
      findSysBannerPage(params).then((res) => {
        if (res.data?.records?.length) {
          this.imageUrl = res.data.records.map((item) => `/api/file/view?code=${item.imageId}`)
        }
      })
    },
    /* 获取图形验证码 */
    getImageCode() {
      getCodeImg().then((res) => {
        this.codeUrl = 'data:image/gif;base64,' + res.img
        this.form.uuid = res.uuid
      })
    },
    /* 设置登录平台 */
    handlePlatform() {
      const params = this.$route.params || {}
      if (!params.type) {
        params.type = 'notaryCloud'
      }
      this.setPlatform(params.type)

      // 加载相应登录页面
      const map = {
        // 公证云
        notaryCloud: {
          loginBtn: true,
          loginTypes: ['vcode', 'pwd']
        },
        // 专业法律服务
        legalService: {
          loginBtn: false,
          loginTypes: ['vcode', 'pwd']
        },
        // 平台运营用户体系
        platformOperation: {
          loginBtn: false,
          loginTypes: ['pwd']
        }
      }
      this.setLoginBtn(map[params.type]?.loginBtn)
      this.loginTypes = map[params.type]?.loginTypes
      this.activeName = this.loginTypes[0]

      if (map[params.type]?.loginBtn && params.type === 'notaryCloud') {
        this.setLoginWay('people')
      }
    },
    handleSmsLogin() {
      const form = this.$refs.codeForm
      form.validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.platform == 'legalService') {
            this.clientFlag = 'LEGAL_SERVICE'
          } else {
            this.clientFlag = 'PLATFORM_OPERATION'
          }
          const user = {
            loginType: 'phone',
            userType: '3',
            clientFlag: this.clientFlag,
            applicationId: this.applicationId,
            username: this.form.phone,
            code: this.form.code,
            uuid: this.phoneUuid,
            allowedUserTypes: this.allowedUserTypes
          }
          if (user.rememberMe) {
            Cookies.set('username', user.username, { expires: Config.passCookieExpires })
            Cookies.set('password', user.password, { expires: Config.passCookieExpires })
            Cookies.set('rememberMe', user.rememberMe, { expires: Config.passCookieExpires })
          } else {
            Cookies.remove('username')
            Cookies.remove('password')
            Cookies.remove('rememberMe')
          }
          this.$store
            .dispatch('OpenLogin', user)
            .then((res) => {
              if (res && res.code) {
                this.getImageCode()
                // 返回错误信息
                this.$refs.userForm.validate(() => {})
                this.$notify({
                  title: res.message,
                  type: 'error',
                  duration: 2500
                })
              } else {
                //成功
                preOpen({
                  applicationId: this.applicationId,
                  loginSource: this.loginSource
                }).then((res) => {
                  if (res) {
                    this.openInfo = res.data
                    if (this.applicationInfo.appUrl) {
                      location.href = this.openInfo.redirectUri
                    } else {
                      this.$notify({
                        title: '该应用未配置应用地址',
                        type: 'error',
                        duration: 2500
                      })
                    }
                  }
                })
              }
              this.loading = false
            })
            .catch(() => {
              this.loading = false
              this.getImageCode()
            })
        }
      })
    },
    handleLogin() {
      const form = this.$refs.userForm
      form.validate((valid) => {
        if (valid) {
          this.submitCount += 1
          this.loading = true
          // 1-公证云,2-专业法律服务,3-平台运营用户体系
          if (this.platform == 'notaryCloud') {
            const user = {
              accountType: this.accountType,
              ...this.form
            }
            if (user.rememberMe) {
              Cookies.set('username', user.username, { expires: Config.passCookieExpires })
              Cookies.set('password', user.password, { expires: Config.passCookieExpires })
              Cookies.set('rememberMe', user.rememberMe, { expires: Config.passCookieExpires })
            } else {
              Cookies.remove('username')
              Cookies.remove('password')
              Cookies.remove('rememberMe')
            }
            // 不需要验证码不传uuid
            if (this.submitCount < 4) {
              delete user.uuid
            }
            this.$store
              .dispatch('UserOpenLogin', user)
              .then((res) => {
                if (res && res.code) {
                  this.getImageCode()
                  // 返回错误信息
                  this.$refs.userForm.validate(() => {})
                  this.$notify({
                    title: res.message,
                    type: 'error',
                    duration: 2500
                  })
                } else {
                  //成功
                  this.$router.push({ path: this.redirect || '/accountInfo' })
                }
                this.loading = false
              })
              .catch(() => {
                this.loading = false
                this.getImageCode()
              })
          } else {
            if (this.platform == 'legalService') {
              this.clientFlag = 'LEGAL_SERVICE'
            } else {
              this.clientFlag = 'PLATFORM_OPERATION'
            }
            const user = {
              ...this.form,
              clientFlag: this.clientFlag,
              applicationId: this.applicationId,
              allowedUserTypes: this.allowedUserTypes,
              password: encrypt(this.form.password),
              passwordEncrypted: true
            }
            if (user.rememberMe) {
              Cookies.set('username', user.username, { expires: Config.passCookieExpires })
              Cookies.set('password', user.password, { expires: Config.passCookieExpires })
              Cookies.set('rememberMe', user.rememberMe, { expires: Config.passCookieExpires })
            } else {
              Cookies.remove('username')
              Cookies.remove('password')
              Cookies.remove('rememberMe')
            }
            // 不需要验证码不传uuid
            if (this.submitCount < 4) {
              delete user.uuid
            }
            this.$store
              .dispatch('OpenLogin', user)
              .then((res) => {
                if (res && res.code) {
                  this.getImageCode()
                  // 返回错误信息
                  this.$refs.userForm.validate(() => {})
                  this.$notify({
                    title: res.message,
                    type: 'error',
                    duration: 2500
                  })
                } else {
                  //成功
                  preOpen({
                    applicationId: this.applicationId,
                    loginSource: this.loginSource
                  }).then((res) => {
                    if (res) {
                      this.openInfo = res.data
                      if (this.applicationInfo.appUrl) {
                        location.href = this.openInfo.redirectUri
                      } else {
                        this.$notify({
                          title: '该应用未配置应用地址',
                          type: 'error',
                          duration: 2500
                        })
                      }
                    }
                  })
                }
                this.loading = false
              })
              .catch(() => {
                this.loading = false
                this.getImageCode()
              })
          }
        }
      })
    },
    /* 获取手机验证码 */
    getPhoneCode() {
      this.$refs.codeForm.validateField(['phone'], () => {
        normalSendCode({
          value: this.form.phone,
          phoneVerify: true
        })
          .then((res) => {
            this.loading = false
            if (res.code === 0) {
              this.phoneUuid = res.data.bizId
              this.countDownPhoneCode()
            } else {
              this.$notify({
                title: res.message,
                type: 'error',
                duration: 2500
              })
            }
          })
          .catch((err) => {
            this.loading = false
            console.error(err.response.data.message)
          })
      })
    },
    /**
     * 对手机验证码进行冷却限制
     */
    countDownPhoneCode() {
      if (this.timer) {
        clearInterval(this.timer)
      }
      this.phoneCodeDisabled = true
      this.phoneCodeCountDownValue = 60
      this.timer = setInterval(() => {
        this.phoneCodeCountDownValue = this.phoneCodeCountDownValue - 1
        if (this.phoneCodeCountDownValue <= 0) {
          clearInterval(this.timer)
          this.phoneCodeDisabled = false
        }
      }, 1000)
    },
    ...mapMutations({
      setLoginWay: 'login/setLoginWay',
      setPlatform: 'login/setPlatform',
      setLoginBtn: 'login/setLoginBtn'
    })
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/css/login.scss';
$mh: 500px;
$imgh: 60vh;
.nc-avatar {
  padding-top: 20px;
}

.login-main {
  z-index: 1;
}

.bg-full {
  background-image: url('../assets/images/login-bg-full.png');
}

/deep/.swiper-slide {
  max-height: $mh;
  max-height: $imgh;
  justify-content: center;

  img {
    max-height: $mh;
    max-height: $imgh;
  }
}

/deep/.el-input__suffix {
  right: 10px;
  display: flex;
  align-items: center;
}

.login-password .eye {
  display: none;
  cursor: pointer;
}

.login-password:hover .eye {
  display: flex;
}
</style>
